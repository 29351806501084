body {
    background-color: #FFF;
}

table thead {
    background-color: transparent;;
}

thead tr th {
    color: #131212 !important;
    border: 1px solid #ece6e6;
    border-width: 1px 0 1px 0 !important;
}
.MuiTableCell-root {
    font-family: "Lato";
}
.display-none {
    display: none;
}

.display-block {
    display: block;
}

tbody tr:hover {
    background-color: #fff !important;
    border: 1px solid #B9B9B9;
}

.disabled-link {
    pointer-events: none;
}

.field-error .MuiOutlinedInput-root fieldset {
    border-color: red;
}

.field-error .MuiFormHelperText-root {
    color: red;
}

.disabled-color {
    color: white !important;
}

.minus-pb10 {
    line-height: 1;
}

table td {
    border: 1px solid #f0eeee;
     border-width: 1px 0 1px 0 !important;
}

.txt-center th {
    text-align: center;
}

.txt-center td {
    text-align: center;
}


.primaryLabel {
    color: #fff;
    font-weight: 600;
    margin-top: 14px;
}

.pd-box-top0 {
    padding-top: 0;
}

.roleTextarea {
    border: 1px solid #ccc;
    margin-top: 10px;
}

.whiteColor svg {
    color: white !important;
}

.MuiOutlinedInput-root,
.MuiButton-root {
    border-radius: 2px;
}

.textSecondary.Mui-disabled {
    color: rgba(255, 255, 255, 0.5) !important;
}

.backgroundPrimary {
    background-color: #0663A7;
}

.sidebarColor {
    color: white !important;
}

/* .MuiListItem-root:hover {
    color: white !important;
} */

.backgroundTransparent {
    background-color: transparent;
}

.textSecondary {
    color: #fff;
    font-weight: 400;
    background-color: transparent;
}

.textSecondaryButton {
    color: #fff;
    font-weight: 400;
    background-color: transparent;
    padding: 10px;
    margin-top: 15px;
}

.textSecondary:active,
.textSecondary:focus,
.textSecondary:hover {
    /* color: #fff !important; */
    font-weight: 400;
}

.textSecondary.active,
.textSecondaryButton:hover {
    background-color: #FFF;
    color: #0663A7;
}

.roleTextareaAdd {
    min-width: 279px;
    margin-left: -3px !important;
}

.roleTextareaEdit {
    min-width: 274px;
    margin-left: -2px !important;
}

.mg-top10 {
    margin-top: 10px;
}

.mg-top60 {
    margin-top: 60px;
}

.mg-bottom10 {
    margin-bottom: 10px;
}

.pd-top10 {
    padding-top: 10px !important;
}

.btn {
    width: 210px;
    height: 50px;
}

.btnPrimary {
    width: 210px;
    height: 50px;

    border: 1px solid #00273d;
    background: -webkit-linear-gradient(left, #00273d, #00273d, #00273d) !important;
    color: #FFF !important;
    transition: background 0.3s ease-in;
    border-radius: 6px;
}

.btnPrimary:hover {
    border: 1px solid #00273d;
    background: -webkit-linear-gradient(left, #00273d, #00273d, #00273d) !important;
    color: white !important;
}
.btn-document.btnBrowse:hover {
    border: 1px solid #10385D;
    background: -webkit-linear-gradient(left, #10385D, #10385D, #10385D) !important;
    color: white !important;
}
.btn-document.btnBrowse {
    width: 40px;
    height: 46px;
    border: 1px solid #10385D;
    background: -webkit-linear-gradient(left, #10385D, #10385D, #10385D);
    color: #FFF !important;
    transition: background 0.3s ease-in;
    border-radius: 6px;
}
.btn-disabled {
    /* background-color: gray !important; */
    /* color: #ccc; */
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.btnDefault {
    background-color: #ffffff !important;
    color: #fff;
    width: 210px;
    height: 50px;
}

.btnDefault:hover {
    background-color: #ccc !important;
    color: #fff;
}

.editRoleSelect {
    width: 100%;
    padding-left: 0;
}

.mg-top0 {
    margin-top: 0 !important;
}

.navLinkColorPrimary {
    color: #172b4d;
}

.required:after {
    content: " *";
    color: red;
}


/* U-Ask Theme Changes */

.logo-white {
    width: 150px;
    height: unset;
    display: flex;
    align-items: center;
    gap: 15.917px;
}

.MuiToolbar-root {
    padding: 0px;
    display: flex;
    justify-content: space-between;
}

#adminDashboard .MuiContainer-root {
    background-color: #FAFAFA;
    border-radius: 20px;
    min-height: 900px;
}

.MuiDrawer-paperAnchorLeft {
    border: none;
}

.MuiTypography-h5 {
    color: var(--dark-grey, #243847);
    font-family: "FS Albert Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.MuiBreadcrumbs-li {
    margin-top: 2px;
}

.backToChat,
.backToChat1 {
    display: flex;
    background-color: #0663A7 !important;
    /* padding: 25px 35px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid #FFF !important;
    float: right;
    height: 40px;
    width: auto;
    color: #FFF !important;
    font-size: 0.8125rem;
}

.backToChat1 {
    margin-right: 0px !important;
}

.backToChat .MuiButton-startIcon {
    animation: slide1 1s ease-in-out infinite;
}

@media screen and (max-width: 650px) {
    .backToChat {
        display: none;
    }
}

.btn-document {
    display: flex;
    width: 140px;
    height: 44px;
    min-width: 100px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    /* margin-top: 7px */
}

.btn-video {
    display: flex;
    width: 192px;
    height: 46px;
    min-width: 130px;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 7px
}

.scrollbar-container {
    border-radius: 0px;
}

.text-container {
    /* display: inline-block; */
    /* border: none; */
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-capital {
    text-transform: capitalize;
}

.document-content {
    /* padding-top: 19px; */
}

.document-content .icon-container {
    position: relative;
    display: inline-block;
}

.document-content .icon-container>img {
    width: 28px;
    height: 28px
}

.document-content .icon-container>p {
    position: absolute;
    top: 30%;
    margin: 0;
    left: -10px;
    font-size: 8px;
    font-weight: bold;
    color: white;
    background-color: royalblue;
    padding: 2px 4px;
    border-radius: 4px;
    text-transform: uppercase;
}

.document-content>span {
    margin-left: 10px;
    margin-top: -24px;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
}

.video-content {
    padding-top: 19px;
}

.video-content>span {
    margin-left: 10px;
    margin-top: 10px;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
}

.btn-newChat {
    float: right;
    display: flex;
    background-color: #10385D !important;
    /* padding: 25px 35px; */
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #FFF !important;
    float: right;
    height: 35px;
    width: 100%;
    color: #FFF !important;
    font-size: 0.8125rem;
    margin: 10px;
}

.btn-newChat:hover {
    background-color: #FFF !important;
    border: 1px solid #FFF !important;
    color: #0663A7 !important;
}

.btn-newChat.disabled {
    opacity: 0.6;
    color: #ccc;
}

.btn-submitFeedback {
    float: right;
    display: flex;
    background-color: #FFF !important;
    /* padding: 25px 35px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid #0663A7 !important;
    float: right;
    height: 40px;
    width: auto;
    color: #0663A7 !important;
    font-size: 0.8125rem;
}

.btn-submitFeedback:hover {
    background-color: #0663A7 !important;
    border: 1px solid #0663A7 !important;
    color: #FFF !important;
}

.btn-submitFeedback.disabled {
    opacity: 0.6;
    color: #ccc;
}

.MuiInputLabel-formControl {
    background-color: white;
}

.MuiIconButton-label>.MuiSvgIcon-fontSizeMedium {
    color: #171c8f;
}

.camelcase {
    text-transform: lowercase;
}

@keyframes slide1 {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 0);
    }
}

.activeSession {
    background: #0663A7;
    color: #FFF;
}

[class*='rtl-']{
    /* direction: rtl !important; */
}

[class*='ltr-']{
    direction: ltr !important;
}

.modelSelectWrapper{
    text-align: left !important;
}

.modelSelectWrapper svg{
    right: 7px !important;
    left: unset !important;
}
.admin-backToChat {
    display: none;
}
.admin-closeButton {
    display: flex;
    background-color: #10385D !important;
    /* padding: 25px 35px; */
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #FFF !important;
    float: right;
    height: 42px;
    width: auto;
    color: #FFF !important;
    font-size: 0.8125rem;
}
.react-datepicker__header {
    background: #10385D !important;
}
.react-datepicker__current-month {
    color: white !important;
}
.react-datepicker__day-name {
    color: white !important;
}
.chartsButtons {
    background-color: #10385D;
    border: 1px solid #10385D;
    color: white;
    margin-left: 10px;
}
.btn-document.btnBrowse {
    width: 40px;
    height: 55px;
    border: 1px solid #10385D;
    background: -webkit-linear-gradient(left, #10385D, #10385D, #10385D);
    color: #FFF !important;
    border-radius: 6px;
    font-size: 0.875rem;
}